import React from "react";
import {HeadFC} from "gatsby";
import {Header} from "../components/header";
import {Footer} from "../components/footer";
import {useParosSkeletonStyle} from "../hooks/use_paros_skeleton";
import {SEO} from "../components/seo";
import {FloatingWhatsAppButton} from "../components/buttons/whatsapp_button";
import {FaWhatsapp} from "@react-icons/all-files/fa/FaWhatsapp";
import {FaMailBulk} from "@react-icons/all-files/fa/FaMailBulk";
import {FaEnvelope} from "@react-icons/all-files/fa/FaEnvelope";
import {FaPhone} from "@react-icons/all-files/fa/FaPhone";

const TransfersPage = () => {
  return (
    <>
      <Header/>
      <main className="h-full">
        <section className="h-full sm:h-1/5 max-w-6xl mx-auto pt-10 pb-14" style={useParosSkeletonStyle()}>
          <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <h1 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 font-poppins">
              Transfers
            </h1>
          </div>
        </section>
        <section className="w-full bg-white py-20 px-8">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl mb-2"><b>Transfers in Paros</b></h2>
            <p>Discover the beauty of Paros with our convenient transfer services. We offer island-wide transfers to any
              destination you desire. Whether you're arriving at the airport, need a ride to your hotel, or want to
              explore the island's hidden gems, Coolcar has you covered.</p>
            <p>We can take you anywhere on Paros Island, including popular destinations like:</p>
            <ul className="list-disc ml-8 mt-2">
              <li>Paros Airport</li>
              <li>Parikia</li>
              <li>Naoussa</li>
              <li>Pounda Beach</li>
              <li>Golden Beach</li>
              <li>Cabana</li>
              <li>Lefkes</li>
              <li>And more!</li>
            </ul>
            <h2 className="text-2xl font-bold mb-2 pt-10">Luggage</h2>
            <p className="mt-4">We understand that your travel experience may involve carrying extra luggage. Whether you're traveling with large suitcases, sports equipment, or other bulky items, we've got you covered.</p>
            <p>Our spacious vehicles are equipped to accommodate a variety of luggage sizes, ensuring you have a comfortable and stress-free transfer. Please let us know about your luggage requirements when you book your transfer, so we can provide the appropriate vehicle and assistance.</p>
            <h2 className="text-2xl font-bold mb-2 pt-10">Contact Us</h2>
            <h3 className="mb-4">Ready to book your transfer or have questions? Reach out to us through email, WhatsApp
              or call us directly!</h3>
            <ul>
              <li><b>Email: </b><a href="mailto:contact@coolcar.gr">contact@coolcar.gr</a></li>
              <li><b>WhatsApp: </b><a href="https://api.whatsapp.com/send?phone=306946394618">(+30) 2284052146</a></li>
              <li><b>Phone: </b><a href="tel:+30 2284052146">(+30) 2284052146</a></li>
            </ul>

            <div className="flex flex-wrap gap-3 mt-8">
              <a href="https://api.whatsapp.com/send?phone=306946394618"
                 aria-label="Send us a message on WhatsApp"
                 className="whatsapp-fixed" target="_blank">
                <FaWhatsapp className="mb-2"/>
              </a>

              <a href="tel:+30 2284052146"
                 aria-label="Call us"
                 className="telephone-button" target="_blank">
                <FaPhone className="mb-2" size="40"/>
              </a>

              <a href="mailto:contact@coolcar.gr"
                 aria-label="Send us an email"
                 className="email-button" target="_blank">
                <FaEnvelope className="mb-2" size="40"/>
              </a>

            </div>

          </div>

        </section>
      </main>
      <Footer/>
    </>
  )
}

export const Head: HeadFC = () => (
  <SEO
    title="Paros Transfers Island-Wide | Coolcar"
    description="Discover the beauty of Paros with Coolcar's reliable transfer services. Contact us via email or WhatsApp for quick arrangements."
  />
);

export default TransfersPage;